import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device';

export const PreGateWrapper = styled.div`
  .navbar-toggler {
    &.collapsed {
      .navbar-toggler-icon {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
	.main {
		background: #000;
		padding-bottom: 100px;
    padding-top: 89px;
		@media ${device.laptopMedium} {
			padding-bottom: 65px;
		}
    @media ${device.desktopsignup} {
			padding-top: 175px;
		}

		.pre-gate-main {
			max-width: 968px;
			background: #f7f7f7;
			margin: 0 auto;
			padding: 40px 80px;

			@media ${device.laptopMedium} {
				padding: 40px 20px;
				width: 90%;
			}

			span {
				text-align: center;

				h2 {
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;

					@media ${device.laptopMedium} {
						font-size: 2.5rem;
						line-height: 3.5rem;
					}
				}

				p {
					font-size: 2.2rem;
					line-height: 3.2rem;
				}
			}

			form {
				width: 100%;

				.checkbox-section {
					.license-info {
						font-size: 2.2rem;
						line-height: 2.6rem;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						color: #000000;
						margin: 0 0 30px;
						text-align: center;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
							line-height: 2.2rem;
						}
					}

					.all-fields {
						font-size: 1.8rem;
						line-height: 2.2rem;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						color: #000000;
						display: none;
					}

					.radio-btn-title {
						font-size: 1.8rem;
						line-height: 2.2rem;
            font-family: "Gotham", Arial, Helvetica, sans-serif;
						color: #000000;
						font-weight: 500;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
							line-height: 2.6rem;
						}
					}

					.custom-checkbox-btn {
						margin: 0 0 25px 21px;

						.form-check-label {
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
							display: inline-block;
							font-weight: 500;
							color: #000000;
							text-align: left;
							font-size: 1.8rem;
							line-height: 2.6rem;
							margin-top: 2px;
						}

						.form-check-input[type="radio"] {
							border: solid 2px #8000be;
							background-color: #fff;
							font-size: 32px;
							height: 32px;
							width: 32px;
							margin: 0 0 0 -1.2em;

							&:checked {
								border: solid 2px #8000be;
								background-color: #8000be;
							}
							&:focus {
								box-shadow: 0 0 0 0.25rem rgb(42 111 243 / 100%);
								border: solid 2px #0151C1;
							}
						}
					}
				}

				.validate-form {
					max-width: 568px;

					.invalid-feedback {
						font-size: 14px;
						margin-top: 10px;
						font-weight: 700;
						color: rgb(224, 7, 7);
						display: block;
					}

					.label-province {
						margin: 50px 0 40px !important;

						.form-label {
							font-size: 1.8rem;
							line-height: 2.6rem;
							display: inline-block;
							font-weight: 500;
							color: #000000;
							text-align: left;
						}

						.form-select {
							border-radius: 4px;
							border: 1px solid #8000BE;
							padding: 10px 16px;
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
							font-size: 2.2rem;
							line-height: 3.2rem;

							&.is-invalid {
								border: 3px solid #e00707;
							}

							&:focus{
								box-shadow: none !important;
							}

							&:focus-visible {
								outline: 1px solid #000;
    						border: 1px solid #000;
							}
						}
					}

					.license-number {
						.form-label {
							font-size: 1.8rem;
							line-height: 2.6rem;
							display: inline-block;
							font-weight: 500;
							color: #000000;
							text-align: left;
						}

						.form-control {
							font-size: 2.2rem;
							line-height: 3.2rem;
							box-shadow: none !important;
							background: transparent;
							border-radius: 4px;
							border: 1px solid #8000BE;
							padding: 10px 16px;
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

							&.is-invalid {
								border: 3px solid #e00707;
							}

							&:focus{
								box-shadow: none !important;
							}

							&:focus-visible {
								outline: 1px solid #000;
    						border: 1px solid #000;
							}
						}
					}
				}

				.checkbox-section {
					&.optional {
						&.gtm-form-signup {
							.invalid-feedback {
								font-size: 14px;
								margin-top: 10px;
								font-weight: 700;
								color: rgb(224, 7, 7);
								display: block;
							}
						}
					}
				}

				.validate-btn-container{
					margin: 0 auto;

					@media ${device.laptopMedium} {
						display: table;
					}

					.o-button {
						background: rgb(128, 0, 190);
						margin: 30px 0;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 1.4rem;
						text-decoration: none;
						z-index: 1;
						position: relative;
						display: inline-block;
						vertical-align: top;
						overflow: hidden;
						padding: 16px 22px;
						border: none;
						letter-spacing: 0;
						border-radius: 6px;
						cursor: pointer;
						min-width: auto;
						font-weight: 700;
						transition: ease-in 0.3s;
						box-shadow: none;

						@media ${device.laptopMedium} {
							width: auto;
							margin: 30px auto 0;
						}

						&:disabled{
							opacity: 0.3;
							pointer-events: none;
							background-color: #300064;
							cursor: default;

							&:after {
								opacity: 0.3;
							}
						}

						&:after {
							content: '';
							width: 17px;
							height: 14px;
							display: inline-block;
							margin-left: 7px;
							background-repeat: no-repeat;
							position: relative;
							top: 0px;
						}

						&:hover {
							background: rgb(255, 255, 255);
    					color: rgb(128, 0, 190);

							&:after {
								top: 0;
								height: 14px;
							}
						}
						&:focus {
							box-shadow: 0 0 0 0.25rem rgb(42 111 243);
							background: #8000BE;
							color: #fff;
							border: 1px solid #fff;
						}
					}
				}
			}
		}
	}

  .footer {
    &.pre-index-footer {
      margin: auto;
      padding: 0;
    }
    .footercontainer {
      footer {
        >.row {
          padding: 0;
        }
      }
    }
  }
	.headerwraphead {
		padding: 20px 0 0;

		@media ${device.midDesktopMax} {
			padding: 0;
		}

		&.show {
			+main {
				padding-top: 175px ;
			}
		}

		.topnav {
			display: none;
		}

		.header-inner {
			@media ${device.midDesktopMax} {
				padding: 10px 0;
			}
		}
	}

	// .footercontainer {
	// 	margin: 0;

	// 	footer {
	// 		.row {
	// 			padding: 0;
	// 		}
	// 	}
	// }
  // .footer{
  //   margin: 0;
  // }

  .o-backtotop-btn {
		display: none;
	 }
`;
