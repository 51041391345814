export const patientPI: any = {
    patMainMenu: "Main Navigational Menu Consumer GCSO",
    patFooterMenu: "Footer Navigation Menu Consumer GCSO",
    patBanner: "Homepage Carousal",
    patFooter: "Pre Index Footer DTC HTML Canada",
    patExitPopup: "Retina Canada Exit Popup HTML",
    patHCPSwitcher: "HCP Switcher Modal Pop Up",
    patTopNav: "Top Navigation Menu Retina Canada HTML DTC",
    patSiteLogo: "Site logos DTC",
    patFirstPara: "HCP Validate Healthcare Provider New",
    redirectLangUrl: {
      "redirectUrl": {
        "en": "/pre-index",
        "fr": "/fr/pre-index"
      }
    },
    backToTopGTM: "Back to top - Pre Index",
    footerClassName: "pre-index-footer"
  }
