import React, { useState } from "react"
import { withPrefix } from "gatsby"
import { Cookies } from "react-cookie-consent"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { HCPValidatorWrapper } from './styles'
import HcpFrmValidation from './Validation/frmValidation'
import { validateFunc } from "./Validation/validateFunc"
import LicenceNoValidate from "./Validation/licenceNoValidation"
import retinaConfig from "../../../utils/retinaConfigs";

const HCPValidateForm = () => {

  const [disabled, setDisabled] = useState(true);
  const [formFieldVal, setFormFieldVal] = useState({})
  const [errors, setErrors] = useState({})
  const formField = { 'userrole': '', 'workIn': '', 'licenceNo': '' }

  const setField = async (event: any, field: any, value: any) => {
    await validateFunc({ setFormFieldVal, formFieldVal, field, value, errors, setErrors, event, validateField, checkAllField })
  }
  const validateField = async (field: any, value: any) => {
    const fieldValidation = await HcpFrmValidation({ field, value })
    if (fieldValidation.isError) {
      setErrors({
        ...errors,
        [field]: fieldValidation.Errormsg
      })
    }
  }
  const checkAllField = async (inputField: any, inputVal: any) => {
    let newErrors = false;
    const thingsArray: { [userrole: string]: string } = {};
    for (const key in formField) {
      const field = key;
      const chk = (formFieldVal[field] === undefined) ? "" : formFieldVal[key]
      const value = field === inputField ? inputVal : chk;
      thingsArray[key] = value;
      const fieldValidation = await HcpFrmValidation({ field, value })
      if (fieldValidation.isError === true) {
        newErrors = true
      }
    }
    newErrors === true ? setDisabled(true) : setDisabled(false);
  }

  const handleSubmit = async (e: any) => {
    let frmValide = true;
    const userrole = formFieldVal.userrole;
    const workIn = formFieldVal.workIn;
    const licenseNo = formFieldVal.licenceNo;
    const licenseNoCheck = !disabled && userrole !== "" && workIn !== "" && licenseNo !== "" ? await LicenceNoValidate({ userrole, workIn, licenseNo }) : null;
    if (licenseNoCheck !== null && licenseNoCheck?.isError === true) {
      frmValide = false;
      setErrors({
        [licenseNoCheck?.field]: licenseNoCheck?.Errormsg
      })
    }

    e.preventDefault()
    if (frmValide && licenseNoCheck !== null) {
      const params = new URLSearchParams(window.location.search)
      let redirectUrl = params.get('redirect') as string
      if (!redirectUrl || redirectUrl === withPrefix(retinaConfig.preIndexUrl)) {
        redirectUrl = withPrefix(retinaConfig.hcpHomeUrl)
      }
      Cookies.set('hcpvalidated', 1)
      window.location.href = redirectUrl
    }
  }

  return (
    <HCPValidatorWrapper>
      <Form className="col-sm-4 m-auto hcp-validator-form" onSubmit={handleSubmit} >
        <Form.Group className="checkbox-section optional gtm-form-signup" controlId="validationRadio">
          <div className="license-info">Please provide your license information</div>
          <p className="o-text--normal radio-btn-title">I am a:</p>
          <Form.Group className="checkbox-section optional gtm-form-signup" controlId="userrolephy">
            <Form.Check className='custom-checkbox-btn custom-radio1'
              label="Physician or Specialist" name="userrole" type="radio" value="Physician or Specialist"
              onChange={(e: any) => { setField(e, 'userrole', e.target.value); }}
            />
          </Form.Group>
          <Form.Group className="checkbox-section optional gtm-form-signup" controlId="userrolenur">
            <Form.Check className='custom-checkbox-btn custom-radio2' label="Nurse" name="userrole"
              type="radio" value="Nurse"
              onChange={(e: any) => { setField(e, 'userrole', e.target.value); }}
            />
          </Form.Group>
          <Form.Group className="checkbox-section optional gtm-form-signup" controlId="userrolecou">
            <Form.Check className='custom-checkbox-btn custom-radio3' label="Genetic Counsellor"
              name="userrole" type="radio" value="Genetic Counsellor"
              onChange={(e: any) => { setField(e, 'userrole', e.target.value); }}
            />
          </Form.Group>
          <Form.Control.Feedback type='invalid'>
            {errors.userrole}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="validate-form">
          <Form.Group className="mb-3 mt-6 label-province" >
            <Form.Label className="o-text--normal" htmlFor="province">I work in:</Form.Label>
            <Form.Select
              id="province" size="sm"
              className="o-text--normal"
              isInvalid={!!errors.workIn}
              onChange={(e: any) => setField(e, 'workIn', e.target.value)}
              onBlur={(e: any) => setField(e, 'workIn', e.target.value)}
            >
              <option value="">Select a province</option>
              <option value="Alberta">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="Manitoba">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NFL">Newfoundland and Labrador</option>
              <option value="Northwest Territories">Northwest Territories</option>
              <option value="NS">Nova Scotia</option>
              <option value="Nunavut">Nunavut</option>
              <option value="Ontario">Ontario</option>
              <option value="PEI">Prince Edward Island</option>
              <option value="Quebec">Quebec</option>
              <option value="Saskatchewan">Saskatchewan</option>
              <option value="Yukon">Yukon</option>
            </Form.Select>
            <Form.Control.Feedback type='invalid'>
              {errors.workIn}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 license-number" controlId="formBasicEmail">
            <Form.Label className="o-text--normal">My license number is:</Form.Label>
            <Form.Control
              type="text"
              className="o-text--normal"
              isInvalid={!!errors.licenceNo}
              onChange={(e: any) => setField(e, 'licenceNo', e.target.value)}
              onBlur={(e: any) => setField(e, 'licenceNo', e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.licenceNo}
            </Form.Control.Feedback>
          </Form.Group>
          <div className='validate-btn-container'>
            <Button variant="primary" type="submit" className="o-text--normal o-button o-button--primary  mb-6" disabled={disabled} >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </HCPValidatorWrapper>
  )
}

export default HCPValidateForm;
