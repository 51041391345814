// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function validateFunc({ setFormFieldVal, formFieldVal, field, value, errors, setErrors, event, validateField, checkAllField }: { setFormFieldVal: any; formFieldVal: any; field: any; value: any; errors: any; setErrors: any; event: any; validateField: (field: any, value: any) => Promise<void>; checkAllField: (inputField: any, inputVal: any) => Promise<void> }): Promise<any> {
  setFormFieldVal({
    ...formFieldVal,
    [field]: value
  });
  if (!!errors[field])
    setErrors({
      ...errors,
      [field]: null
    });
  await validateField(field, value);
  await checkAllField(field, value);
  return value;
}
