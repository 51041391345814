import styled from "styled-components";
import {device} from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const HCPValidatorWrapper = styled.div`
  form {
    width: 100%;

    .checkbox-section {
      .license-info {
        font-size: 2.2rem;
        line-height: 2.6rem;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
        color: #000000;
        margin: 30px 0;
        text-align: center;

        @media ${device.laptopMedium} {
          font-size: 1.8rem;
          line-height: 2.2rem;
        }
      }

      .all-fields {
        font-size: 1.8rem;
        line-height: 2.2rem;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
        color: #000000;
      }

      .radio-btn-title {
        font-size: 1.8rem;
        line-height: 2.2rem;
        font-family: "Gotham", Arial, Helvetica, sans-serif;
        color: #000000;
        font-weight: 500;

        @media ${device.laptopMedium} {
          font-size: 1.8rem;
          line-height: 2.6rem;
        }
      }

      .custom-checkbox-btn {
        margin: 0 0 25px 21px;

        .form-check-label {
          font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
          display: inline-block;
          font-weight: 500;
          color: #000000;
          text-align: left;
          font-size: 1.8rem;
          line-height: 2.6rem;
          margin-top: 2px;
        }

        .form-check-input[type="radio"] {
          border: solid 2px #8000be;
          background-color: #fff;
          font-size: 32px;
          height: 32px;
          width: 32px;
          margin: 0 0 0 -1.2em;

          &:checked {
            border: solid 2px #8000be;
            background-color: #8000be;
          }
        }
      }
    }

    .validate-form {
      max-width: 568px;

      .invalid-feedback {
        font-size: 14px;
        margin-top: 10px;
        font-weight: 700;
        color: rgb(224, 7, 7);
        display: block;
      }

      .label-province {
        margin: 50px 0 40px !important;

        .form-label {
          font-size: 1.8rem;
          line-height: 2.6rem;
          display: inline-block;
          font-weight: 500;
          color: #000000;
          text-align: left;
        }

        .form-select {
          border-radius: 4px;
          border: 1px solid #8000BE;
          padding: 10px 16px;
          font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
          font-size: 2.2rem;
          line-height: 3.2rem;

          &.is-invalid {
            border: 3px solid #e00707;
          }

          &:focus{
            box-shadow: none !important;
          }

          &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
          }
        }
      }

      .license-number {
        .form-label {
          font-size: 1.8rem;
          line-height: 2.6rem;
          display: inline-block;
          font-weight: 500;
          color: #000000;
          text-align: left;
        }

        .form-control {
          font-size: 2.2rem;
          line-height: 3.2rem;
          box-shadow: none !important;
          background: transparent;
          border-radius: 4px;
          border: 1px solid #8000BE;
          padding: 10px 16px;
          font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

          &.is-invalid {
            border: 3px solid #e00707;
          }

          &:focus{
            box-shadow: none !important;
          }

          &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
          }
        }
      }
    }

    .checkbox-section {
      &.optional {
        &.gtm-form-signup {
          .invalid-feedback {
            font-size: 14px;
            margin-top: 10px;
            font-weight: 700;
            color: rgb(224, 7, 7);
            display: block;
          }
        }
      }
    }

    .validate-btn-container{
      margin: 0 auto;

      @media ${device.laptopMedium} {
        display: table;
      }

      .o-button {
        margin: 30px 0;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 1.4rem;
        text-decoration: none;
        z-index: 1;
        position: relative;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        padding: 16px 22px;
        border: none;
        letter-spacing: 0;
        border-radius: 6px;
        cursor: pointer;
        min-width: auto;
        font-weight: 700;
        transition: ease-in 0.3s;
        box-shadow: none;

        @media ${device.laptopMedium} {
          width: auto;
          margin: 30px auto 100px;
        }

        &:disabled{
          opacity: 0.3;
          pointer-events: none;
          background-color: #300064;
          cursor: default;

          &:after {
            opacity: 0.3;
          }
        }

        &:after {
          content: '';
          width: 16px;
          height: 17px;
          display: inline-block;
          margin-left: 7px;
          background-repeat: no-repeat;
          position: relative;
          top: 3px;
        }

        &:hover {
          &:after {
            top: 0;
            height: 14px;
          }
        }
      }
    }
  }
`;
