import { ErrorObj, ErrorMessages } from "../../types";

export interface Props {
	field?: any;
	value?: any;
}

/** Rendering template for Patient Sign Up Validation
 *
 * @param param0 props
 * @returns
 */
const HcpFrmValidation = async ({ field, value }: Props): Promise<ErrorObj> => {
	let errorObj: ErrorObj = { "isError": false, "field": field, "value": value, "Errormsg": ErrorMessages.noError }
	errorObj = validateFunc({ field, value, errorObj });
	return errorObj;
}

export default HcpFrmValidation

function validateFunc({ field, value, errorObj }: { field: any; value: any; errorObj: ErrorObj; }): ErrorObj {
	if (field === "userrole" && value === "") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.specialityError };
	}
	if (field === "workIn" && value === "") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.provinceError };
	}
	if (field === "licenceNo" && value === "") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.licenseNoError };
	}
	return errorObj;
}
