import { ErrorObj, ErrorMessages } from "../../types";

export interface Props {
	userrole: string;
	workIn: string;
	licenseNo: string;
}

/** Rendering template for Patient Sign Up Validation
 *
 * @param param0 props
 * @returns
 */
const LicenceNoValidate = async ({ userrole, workIn, licenseNo }: Props) => {
	let errorObj: ErrorObj = { "isError": false, "field": "", "value": "", "Errormsg": ErrorMessages.noError }
	if (workIn !== "" || userrole !== "" || licenseNo !== "") {
		let isErrorLicenseNo = false;
		switch (userrole) {
			case "Physician or Specialist":
				isErrorLicenseNo = getPhysicalLicProvince({ workIn, licenseNo });
				break;
			case "Nurse":
				isErrorLicenseNo = getNurseLicProvince({ workIn, licenseNo });
				break;
			case "Genetic Counsellor":
				isErrorLicenseNo = getGenCounLicProvince({ workIn, licenseNo });
				break;
			default:
				break;
		}
		const retval = (isErrorLicenseNo === null) ? false : true;
		if (!retval) {
			errorObj = { "isError": true, "field": "licenceNo", "value": licenseNo, "Errormsg": ErrorMessages.licenseCombinationError }
		}
		return errorObj;
	}
}

export default LicenceNoValidate


function getGenCounLicProvince({
	workIn,
	licenseNo
}: {
	workIn: string | undefined;
	licenseNo: string | undefined;
}): any {
	let match;
	switch (workIn) {
		case "BC":
			match = licenseNo?.match(/^[0-9]{5,6}$/);
			break;
		case "Alberta":
			match = licenseNo?.match(/^[0-9]{4,5}$/);
			break;
		case "Saskatchewan":
			match = licenseNo?.match(/^[a-zA-Z][0-9]{4}$/);
			break;
		case "Manitoba":
			match = licenseNo?.match(/^[0-9]{5}$/);
			break;
		case "Ontario":
			match = licenseNo?.match(/^[0-9]{4,7}$/);
			break;
		case "Quebec":
			match = licenseNo?.match(/^[0-9]{6}$/);
			break;
		case "NB":
			match = licenseNo?.match(/^[0-9]{4,5}$/);
			break;
		case "PEI":
			match = licenseNo?.match(/^[a-zA-Z]?[0-9]{4,5}$/);
			break;
		case "NFL":
			match = licenseNo?.match(/^[0-9]{2}[-]?[0-9]{3,4}$/);
			break;
		case "NS":
			match = licenseNo?.match(/^[0-9]{2,4}$/);
			break;
		case "Yukon":
			match = licenseNo?.match(/^[0-9]{4}[-]?[0-9][-]?[0-9]{4}$/);
			break;
		case "Northwest Territories":
			match = licenseNo?.match(/^[0-9]{4,5}$/);
			break;
		case "Nunavut":
			match = licenseNo?.match(/^(PHAR-)?[0-9]{4}-?[0-9]{3,}$/);
			break;
		default:
			match = false;
			break;
	}
	return match;
}

function getNurseLicProvince({
	workIn,
	licenseNo }: {
		workIn: string | undefined;
		licenseNo: string | undefined;
	}): any {
	let match
	switch (workIn) {
		case "BC":
			match = licenseNo?.match(/^[0-9]{7}$/);
			break;
		case "Alberta":
			match = licenseNo?.match(/^[0-9]{5,6}$/);
			break;
		case "Saskatchewan":
			match = licenseNo?.match(/^[0-9]{5}$/);
			break;
		case "Manitoba":
			match = licenseNo?.match(/^[0-9]{6}$/);
			break;
		case "Ontario":
			match = licenseNo?.match(/^[0-9]{7,9}$/);
			break;
		case "Quebec":
			match = licenseNo?.match(/^[0-9]{2,3}[\ \-]?[0-9]{4}$/);
			break;
		case "NB":
			match = licenseNo?.match(/^[0-9]{6}$/);
			break;
		case "PEI":
			match = licenseNo?.match(/^[0-9]{6}$/);
			break;
		case "NFL":
			match = licenseNo?.match(/^[0-9]{4}$/) || licenseNo?.match(/^[0-9]{5}$/);
			break;
		case "NS":
			match = licenseNo?.match(/^[0-9]{4,5}$/);
			break;
		case "Yukon":
			match = licenseNo?.match(/^[0-9]{4,5}$/);
			break;
		case "Northwest Territories":
			match = licenseNo?.match(/^[0-9]{4,5}$/);
			break;
		case "Nunavut":
			match = licenseNo?.match(/^[0-9]{2,6}$/);
			break;
		default:
			match = false;
			break;
	}
	return match;
}

function getPhysicalLicProvince({
	workIn,
	licenseNo
}: {
	workIn: string | undefined;
	licenseNo: string | undefined;
}): any {
	let match;
	switch (workIn) {
		case "BC":
			match = licenseNo?.match(/^[0-9]{5,6}$/);
			break;
		case "Alberta":
			match = licenseNo?.match(/^0[0-9]{5}$/);
			break;
		case "Saskatchewan":
			match = licenseNo?.match(/^[0-9]{6}$/);
			break;
		case "Manitoba":
			match = licenseNo?.match(/^[0-9-]{5,6}$/);
			break;
		case "Ontario":
			match = licenseNo?.match(/^[0-9]{5,7}$/);
			break;
		case "Quebec":
			match = licenseNo?.match(/^00[0-9]{3}$/);
			break;
		case "NB":
			match = licenseNo?.match(/^[0-9]{1,5}$/);
			break;
		case "PEI":
			match = licenseNo?.match(/^[a-zA-Z]{0,2}[0-9]{4}$/);
			break;
		case "NFL":
			match = licenseNo?.match(/^[a-zA-Z]?[\ \-]?[0-9]{5}$/);
			break;
		case "NS":
			match = licenseNo?.match(/^[0-9]{6}$/);
			break;
		case "Yukon":
			match = licenseNo?.match(/^[0-9]{4}[-]?[0-9][-]?[0-9]{4}$/);
			break;
		case "Northwest Territories":
			match = licenseNo?.match(/^[0-9]{4,5}$/);
			break;
		case "Nunavut":
			match = licenseNo?.match(/^[0-9]{4}[-]?[0-9]{3}$/);
			break;
		default:
			match = false;
			break;
	}
	return match;
}
